import React from "react";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

import NavPills from "components/NavPills/NavPills.js";

// @material-ui/icons

// core components
import GridContainer from "components/Grid/GridContainer.js";
import GridItem from "components/Grid/GridItem.js";

import styles from "assets/jss/material-kit-react/views/landingPageSections/teamStyle.js";
import "./Section.css";

const useStyles = makeStyles(styles);

export default function ExperienceSection() {
  const classes = useStyles();

  return (
    <div className={classes.section}>
      <h2 className={classes.title}>Experience</h2>
      <div style={{ marginTop: "100px" }}>
        <GridContainer justify="center">
          <GridItem xs={9} sm={12} md={12} lg={6}>
            <NavPills
              color="rose"
              horizontal={{
                tabsGrid: { xs: 12, sm: 4, md: 3 },
                contentGrid: { xs: 12, sm: 8, md: 8 },
              }}
              tabs={[
                {
                  tabButton: "IBM",

                  tabContent: (
                    <span style={{ textAlign: "left" }}>
                      <h3 style={{ color: "#6c757d", margin: "0" }}>
                        Software Engineer
                      </h3>
                      <h5 style={{ color: "#6c757d" }}>
                        January 2021 - Present
                      </h5>
                      <div>
                        <p>- Contributed to open source Presto and Velox</p>
                        <div style={{ marginLeft: '20px' }}>
                          <p>- Enhanced tracking of current system memory usage for out of memory detection in Prestissimo on Linux machines</p>
                          <p>- Enhanced the window fuzzer in Velox to test further scenarios for testing window functions</p>
                          <p>- Converted two performance workloads to run on Presto/Velox to test its performance</p>
                          <p>- Developed and deployed online infrastructure to deploy Prestissimo clusters with SSD caching</p>
                          <p>- Created Grafana dashboards to view metrics from performance workload runs and analyze performance</p>
                        </div>
                      </div>
                      <p>
                        - Developed and improved QA infrastructure for a test bucket that tests Db2 database engine code dealing with database upgrades and restores
                      </p>
                      <p>
                        - Provided QA support to IBM clients via Salesforce regarding Db2 catalog issues
                      </p>
                      <p>
                        - Investigated and fixed testcases in major test buckets for my team in Db2
                      </p>
                      <p>
                        - Collaborated and developed with fellow new hires on different teams on a full-stack web app project written in React and Golang that serves to be a dashboard to show users their provisioned resources on IBM Cloud in real-time and notify them when it is currently being under-utilized
                      </p>
                    </span>
                  ),
                },
                {
                  tabButton: "Rocket Software",

                  tabContent: (
                    <span style={{ textAlign: "left" }}>
                      <h3 style={{ color: "#6c757d", margin: "0" }}>
                        Software Engineering Intern
                      </h3>
                      <h5 style={{ color: "#6c757d" }}>
                        June 2019 - December 2019
                      </h5>
                      <p>
                        - Used React and Java to create new components and
                        improve existing features of a UI web application inside
                        Zowe
                      </p>
                      <p>
                        - Worked in an agile environment where we had daily
                        scrum meetings and a Jira ticket tracking system
                      </p>
                      <p>
                        - Assisted with coding REST API endpoints as needed for
                        new product enhancements
                      </p>
                      <p>
                        - Gained experience with Git/Bitbucket, Jenkins, legacy
                        software, Db2, and the Mainframe
                      </p>
                      <p>
                        - Collaborated and presented working prototypes to
                        customers, executives, and UX designers
                      </p>
                    </span>
                  ),
                },
                {
                  tabButton: "De Anza College",

                  tabContent: (
                    <span style={{ textAlign: "left" }}>
                      <h3 style={{ color: "#6c757d", margin: "0" }}>
                        Mathematics Tutor
                      </h3>
                      <h5 style={{ color: "#6c757d" }}>May 2016 - June 2017</h5>
                      <p>
                        - Tutored students seeking help with math, specifically
                        in statistics and algebra
                      </p>
                    </span>
                  ),
                },
              ]}
            />
          </GridItem>
          {/* <GridItem xs={12} sm={12} md={8} className={classes.navWrapper}>
            <NavPills
              alignCenter
              color="primary"
              tabs={[
                {
                  tabButton: "School",
                  tabIcon: School,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={8}>
                        <h3>San Jose State University</h3>
                        <h5>Bachelor of Science: Computer Science</h5>
                        <p>
                          Coursework in: Artificial Intelligence,
                          Bioinformatics, Data Structures and Algorithms,
                          Computer Networks, Information Security, Object
                          Oriented Design, Database Systems, Operating Systems,
                          Programming Paradigms
                        </p>
                      </GridItem>
                    </GridContainer>
                  )
                },
                {
                  tabButton: "Work",
                  tabIcon: Work,
                  tabContent: (
                    <GridContainer justify="center">
                      <GridItem xs={12} sm={12} md={8}>
                        <h3>Rocket Software</h3>
                        <h5>Software Engineering Intern</h5>
                        <ul>
                          <p>
                            Used React and Java to create new components and
                            improve existing features of a UI web application
                            inside Zowe
                          </p>
                          <p>
                            Worked in an agile environment where we had daily
                            scrum meetings and a Jira ticket tracking system
                          </p>
                          <p>
                            Assisted with coding REST API endpoints as needed
                            for new product enhancements
                          </p>
                          <p>
                            Gained experience with Git/Bitbucket, Jenkins,
                            legacy software, Db2, and the Mainframe
                          </p>
                          <p>
                            Collaborated and presented working prototypes to
                            customers, executives, and UX designers
                          </p>
                        </ul>
                      </GridItem>
                    </GridContainer>
                  )
                }
              ]}
            />
          </GridItem> */}
        </GridContainer>
      </div>
    </div>
  );
}
